.app-container {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  text-align: left;
}

.app {
  width: 1600px;
  margin: 0 auto;

  letter-spacing: 0.5px;
}

@media (max-width: 1000px) {
  .help-tooltip-container {
    display: none;
  }
}

h1 {
  font-size: 2rem;
}

section {
  font-size: 1rem;
  background: #f5f5f5;
  padding: 25px;
}

section.minor {
  padding: 15px 25px;
}

section:first-of-type {
  margin-top: 0;
}

.section-head {
  font-size: 1rem;
  background: #f5f5f5;
  padding: 15px 25px;
}

.section-head:not(:last-child) {
  border-bottom: 1px solid lightgray;
}

.section-head h3 {
  margin: 0;
}

.section-head h3 svg {
  margin-left: 15px;
}

.backdrop {
  margin: 25px 0;
  box-shadow: 0 1px 2px #bbb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

label {
  display: block;
  padding-bottom: 5px;
  color: #555;
}

select {
  padding: 0 10px;
  height: 40px;
  line-height: 39px;
  border: 1px solid transparent;
  border-bottom: 1px solid #ccc;
  outline: none;
  color: #666;
  background: white;
}

select:focus {
  border-bottom: 1px solid #777;
  color: black;
}

input[type="text"],
input[type="number"],
input[type="password"] {
  font-size: 14px;
  padding: 0 10px;
  height: 40px;
  line-height: 39px;
  background: white;
  border: 0;
  outline: none;
  border-bottom: 1px solid #ccc;
  transition: border-bottom 0.3s;
  width: 80%;
  color: #666;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="password"]:focus {
  border-bottom: 1px solid #777;
  color: black;
}

.help-tooltip-container {
  display: inline-block;
  position: relative;
}

.tooltip {
  position: absolute;
  left: 40px;
  top: 0;
  background: rgba(0, 0, 0, 0.9);
  color: #aaa;
  padding: 10px 15px;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.2px;
  z-index: 9999;
}

.tooltip p {
  margin-top: 0;
}

.tooltip p:last-child {
  margin-bottom: 0;
}

.submit {
  padding: 15px 20px;
  color: white;
  border: 0;
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  letter-spacing: 1px;
  border-bottom: 1px solid #44a16f;
}

.submit.left {
  float: left;
  margin-right: 15px;
}

.submit.right {
  float: right;
  margin-left: 15px;
}

.submit.green {
  background: #34d17a;
}

.submit.green:hover, .submit.green:focus {
  background: #6ce69b;
  border-bottom: 1px solid #74c18f;
}

.tooltip.reversed {
  left: auto;
  right: calc(100%);
}


.challenge-data {
  margin-top: 15px;
}

.challenge,
.success,
.failure {
  margin: 15px 0;
}


.left {
  text-align: left;
}

.right {
  text-align: right;
}

.row {
  display: flex;
  justify-content: flex-start;
}

.tag-select {
  position: relative;
}

.tag-select .icon {
  position: relative;
  left: -25px;
  top: 1px;
  cursor: pointer;
  opacity: 0.5;
}

.tag-select .icon:hover {
  opacity: 0.75;
}

.tag-select .selected-items {
  position: absolute;
  width: 80%;
  top: 41px;
  border-top: 0;
  z-index: 999;
}

.tag-select .selected-items .item {
  padding: 10px;
  background: #eee;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  font-size: .9rem;
  cursor: pointer;
}

.tag-select .selected-items .item svg {
  margin-right: 10px;
}

.col.flex-start {
  display: flex;
  justify-content: flex-start;
  position: relative;
}

.col.flex-end {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

svg.new {
  margin-left: 15px;
  cursor: pointer;
  color: #34d17a;
}

svg.new:hover {
  color: #6ce69b;
}

svg.add {
  margin-left: 15px;
  cursor: pointer;
  color: #4d8fff;
}

svg.add:hover {
  color: #78b4ff;
}

svg.remove {
  float: right;
  cursor: pointer;
  color: #ff5a5d;
}

svg.remove:hover {
  color: #ff7677;
}

svg.min-max {
  float: right;
  cursor: pointer;
  color: #4d8fff;
}

svg.min-max:hover {
  color: #78b4ff;
}

.challenge-enemy:not(:last-child),
.challenge-item-drop:not(:last-child) {
  margin-bottom: 10px;
}

.col {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
}

.col.no-pad, .col.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.col-1 {
  width: 100%;
}

.col-2 {
  width: 50%;
}

.col-2-3 {
  width: 66.66%;
}

.col-2-5 {
  width: 40%;
}

.col-3 {
  width: 33.33%;
}

.col-3-4 {
  width: 75%;
}

.col-4 {
  width: 25%;
}

.col-4-5 {
  width: 80%;
}

.col-5 {
  width: 20%;
}

.col-5-6 {
  width: 83.33%;
}

.col-6 {
  width: 16.66%;
}

.col-8 {
  width: 12.5%;
}

.col-9-10 {
  width: 90%;
}

.col-10 {
  width: 10%;
}